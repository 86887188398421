<template>
  <v-navigation-drawer
    width="250"
    fixed
    app
    floating
    :expand-on-hover="mini"
    :value="drawer"
  >
    <v-list-item class="pa-0">
      <v-list-item-content class="pa-0">
        <v-list-item-title class="title d-flex align-center mb-0">
          <div class="v-navigation-drawer-brand pa-5 d-flex align-center">
            <img src="@/assets/img/logo-dog.png" width="150" />
          </div>

          <div
            class="drawer-toggler pa-5 cursor-pointer"
            :class="{ active: togglerActive }"
            @click="minifyDrawer"
          >
            <div class="drawer-toggler-inner">
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
              <i class="drawer-toggler-line bg-default"></i>
            </div>
          </div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list nav dense>
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsDashboard"
          :key="i"
          link
          :to="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <v-list-group
        :ripple="false"
        v-for="item in items"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.action"
        append-icon="fas fa-angle-down me-2"
        class="pb-1"
        active-class="item-active"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          :ripple="false"
          link
          class="mb-0 no-default-hover"
          v-for="child in item.items"
          :key="child.title"
          :to="child.link"
        >
          <span
            class="v-list-item-mini d-flex justify-center my-2"
            v-text="child.prefix"
          ></span>

          <v-list-item-content>
            <v-list-item-title v-text="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group
          v-if="item.title === 'Components'"
          prepend-icon=""
          :ripple="false"
          sub-group
          no-action
        >
          <template v-slot:activator>
            <span class="v-list-item-mini d-flex justify-center my-2">ML</span>
            <v-list-item-content>
              <v-list-item-title>Multi Level</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="(item, i) in thirdLevelSimple"
            :key="i"
            :ripple="false"
          >
            <v-list-item-content>
              <v-list-item-title v-text="item"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list-group>

      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsSimple"
          :key="i"
          link
          :to="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>

      <div class="border-bottom ma-3"></div>

      <h5
        class="text-uppercase text-caption ls-0 font-weight-600 p-0 text-muted mx-3 mb-4 d-none-mini white-space-nowrap"
      >
        Account
      </h5>

      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in itemsDocs"
          :key="i"
          link
          @click="goto(item.title)"
          :to="item.link"
          class="pb-1 no-default-hover"
          :ripple="false"
          active-class="item-active"
        >
          <v-list-item-icon>
            <v-icon v-text="item.action"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "drawer",
  props: {
    drawer: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    mini: false,
    togglerActive: false,
    itemsDocs: [
      {
        action: "fa fa-users v-icon-drawer",
        link:
          "/customers",
        title: "Customers",
      },
      {
        action: "fa fa-cog v-icon-drawer",
        link:
          "settings",
        title: "Settings",
      },
      {
        action: "ni ni-button-power v-icon-drawer",
        link:
          "",
        title: "Logout",
      },
      
    ],
    thirdLevelSimple: [
      "Third level menu",
      "Just another link",
      "One last link",
    ],
    itemsSimple: [
      {
        action: "ni-archive-2 v-icon-drawer text-success",
        link: "/widgets",
        title: "Widgets",
      },
      {
        action: "ni-chart-pie-35 v-icon-drawer text-info",
        link: "/charts",
        title: "Charts",
      },
      {
        action: "ni-calendar-grid-58 v-icon-drawer text-danger",
        link: "/calendar",
        title: "Calendar",
      },
    ],
    itemsDashboard: [
      {
        action: "ni-shop v-icon-drawer text-primary",
        // items: [
        //   { title: "Dashboard", prefix: "D", link: "/dashboard" },
        //   { title: "Alternative", prefix: "A", link: "/alternative" },
        // ],
        active: true,
        title: "Dashboards",
        link: "/dashboard",
      },
    ],
    items: [

      {
        action: "ni-ungroup v-icon-drawer text-warning",
        items: [
          { title: "Pricing", prefix: "P", link: "/pricing" },
          { title: "Login", prefix: "L", link: "/login" },
          { title: "Register", prefix: "R", link: "/register" },
          { title: "Lock", prefix: "L", link: "/lock" },
          { title: "Timeline", prefix: "T", link: "/timeline" },
          { title: "Profile", prefix: "P", link: "/profile" },
        ],
        title: "Examples",
      },
      {
        action: "ni-ui-04 v-icon-drawer text-info",
        items: [
          { title: "Buttons", prefix: "B", link: "/components/buttons" },
          { title: "Cards", prefix: "C", link: "/components/cards" },
          { title: "Grid", prefix: "G", link: "/components/grid" },
          {
            title: "Notifications",
            prefix: "N",
            link: "/components/notifications",
          },
          { title: "Icons", prefix: "I", link: "/components/icons" },
          { title: "Typography", prefix: "T", link: "/components/typography" },
        ],
        title: "Components",
      },
      {
        action: "ni-single-copy-04 v-icon-drawer text-pink",
        items: [
          { title: "Elements", prefix: "E", link: "/forms/elements" },
          { title: "Components", prefix: "C", link: "/forms/components" },
          { title: "Validation", prefix: "V", link: "/forms/validation" },
        ],
        title: "Forms",
      },
      {
        action: "ni-align-left-2 v-icon-drawer text-default",
        items: [
          { title: "Tables", prefix: "T", link: "/tables/regular" },
          { title: "Sortable", prefix: "T", link: "/tables/sortable" },
          {
            title: "Paginated Tables",
            prefix: "PT",
            link: "/tables/paginated",
          },
        ],
        title: "Tables",
      },
      {
        action: "ni-map-big v-icon-drawer text-primary",
        items: [
          { title: "Google", prefix: "G", link: "/maps/google" },
          { title: "Vector", prefix: "V", link: "/maps/vector" },
        ],
        title: "Maps",
      },
    ],
  }),
  methods: {
    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },
    goto(link){
      if(link == 'Logout'){
        this.logout()
      }
    },
    logout() {
        let headers = {
            Authorization: "Bearer " + localStorage.getItem('token')
        }
      axios
        .get("/api/logout", {headers: headers})
        .then(response => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("is_admin");
            localStorage.removeItem("next_payment_date");
            localStorage.removeItem("plan");
            localStorage.removeItem("recurring_type");
            localStorage.removeItem("card_last");
            this.$router.push("/");
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem("token")
            localStorage.removeItem("user_id");
            localStorage.removeItem("email");
            localStorage.removeItem("name");
            localStorage.removeItem("next_payment_date");
            localStorage.removeItem("plan");
            localStorage.removeItem("recurring_type");
            localStorage.removeItem("card_last");
            this.$router.push("/");
            location.reload();
        });
    },
  },
};
</script>
