<template>
  <span>
    <template v-if="auth">
      <v-footer color="transparent" class="mt-10 pt-10 pb-5">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                <div class="copyright text-body-2 ls-0 text-muted">
                  © {{ new Date().getFullYear() }}
                  <a
                    href="https://www.pcs720.com/"
                    class="text-decoration-none btn-hover text-primary text-body-2 ls-0 font-weight-600 ms-1"
                    target="_blank"
                    >PCS720</a
                  >
                </div>
              </v-col>

              <v-col cols="12" md="6" class="d-flex justify-end">
                <ul
                  v-for="item in footer"
                  :key="item.linkName"
                  class="d-flex list-style-none"
                >
                  <li>
                    <a
                      :href="item.link"
                      class="text-decoration-none text-muted text-body-2 ls-0 btn-hover no-default-hover"
                      target="_blank"
                      >{{ item.linkName }}</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>

    <template v-else>
      <v-footer color="transparent" class="px-6">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                <div class="copyright text-body-2 ls-0 text-muted">
                  © {{ new Date().getFullYear() }}
                  <a
                    href="https://www.pcs720.com/"
                    class="text-decoration-none text-primary text-body-2 ls-0 font-weight-600 ms-1"
                    target="_blank"
                    >PCS720</a
                  >
                </div>
              </v-col>

              <v-col cols="12" md="6" class="d-flex justify-end">
                <ul
                  v-for="item in footer"
                  :key="item.linkName"
                  class="d-flex list-style-none"
                >
                  <li>
                    <a
                      :href="item.link"
                      class="text-decoration-none text-muted text-body-2 ls-0 btn-dark-hover no-default-hover"
                      target="_blank"
                      >{{ item.linkName }}</a
                    >
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [

        {
          linkName: "About Us",
          link: "",
        },
        {
          linkName: "Blog",
          link: "",
        },
        {
          linkName: "License",
          link: "",
        },
      ],
    };
  },
};
</script>
