import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import DashboardPlugin from "./plugins/dashboard-plugin";
import VueToast from 'vue-toast-notification';
import axios from "axios";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);
Vue.config.productionTip = false;
const http = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers : {
              Authorization:"Bearer " + localStorage.getItem('token')
      },
});
window.axios = http;

// plugin setup
Vue.use(DashboardPlugin);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
