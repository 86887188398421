<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    flat
  >
    <v-container>
      <v-row>
        <v-col cols="6" class="d-flex align-center">
          <v-img
            max-width="100"
            max-height="100"
            src="@/assets/img/logo-new.png"
            class="me-5"
          ></v-img>

          <template v-if="!$vuetify.breakpoint.mobile">
            <v-btn
              v-for="item in links"
              :key="item.name"
              :ripple="false"
              color="transparent"
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-white text-capitalize ls-0"
              depressed
              link
              :to="item.link"
            >
              {{ item.name }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar",
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  data() {
    return {
      dialog: false,
      social: [
        {
          icon: "fab fa-facebook-square",
          tooltip: "Like us on Facebook",
          link: "https://www.facebook.com/creativetim",
        },
        {
          icon: "fab fa-instagram",
          tooltip: "Follow us on Instagram",
          link: "https://www.instagram.com/creativetimofficial",
        },
        {
          icon: "fab fa-twitter-square",
          tooltip: "Follow us on Twitter",
          link: "https://twitter.com/creativetim",
        },
        {
          icon: "fab fa-github",
          tooltip: "Star us on Github",
          link: "https://github.com/creativetimofficial",
        },
      ],
      links: [
        
       
      ],
    };
  },
};
</script>
