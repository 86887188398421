import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout";

// Dashboard pages
const Dashboard = () => import("../views/Dashboard/Dashboard.vue");
const AlternativeDashboard = () =>
  import("../views/Dashboard/AlternativeDashboard.vue");
const Calendar = () => import("../views/Dashboard/Calendar.vue");
const Settings = () => import("../views/Dashboard/Settings.vue");
const Customers = () => import("../views/Dashboard/Customers.vue");
const Charts = () => import("../views/Dashboard/Charts.vue");
const Widgets = () => import("../views/Dashboard/Widgets.vue");

// Components pages
const Buttons = () => import("../views/Components/Buttons.vue");
const Grid = () => import("../views/Components/Grid.vue");
const Icons = () => import("../views/Components/Icons.vue");
const Typography = () => import("../views/Components/Typography.vue");
const Cards = () => import("../views/Components/Cards.vue");
const Notifications = () => import("../views/Components/Notifications.vue");

// Maps pages
const GoogleMaps = () => import("../views/Maps/Google.vue");
const VectorMaps = () => import("../views/Maps/Vector.vue");

// Tables pages
const RegularTables = () => import("../views/Tables/RegularTables.vue");
const SortableTables = () => import("../views/Tables/SortableTables.vue");
const PaginatedTables = () => import("../views/Tables/PaginatedTables.vue");

// Forms pages
const FormElements = () => import("../views/Forms/FormElements.vue");
const FormComponents = () => import("../views/Forms/FormComponents.vue");
const FormValidation = () => import("../views/Forms/FormValidation.vue");

// Pages
const Pricing = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const Login = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");
const Profile = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Profile.vue");
const Timeline = () =>
  import(/* webpackChunkName: "pages" */ "@/views/Pages/Timeline.vue");

Vue.use(VueRouter);
const isLoggedIn = (to, from, next) => {
  if (localStorage.getItem('token')) {
    console.log('isLoggedIn ok...', localStorage.getItem('name'))
    next()
  }
  else {
    next('/login');
    // window.location.reload();
  }
}
let componentsMenu = {
  path: "/components",
  component: DashboardLayout,
  redirect: "/components/buttons",
  name: "Components",
  children: [
    {
      path: "buttons",
      name: "Buttons",
      components: { default: Buttons },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "cards",
      name: "Cards",
      components: { default: Cards },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "grid",
      name: "Grid",
      components: { default: Grid },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "notifications",
      name: "Notifications",
      components: { default: Notifications },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "icons",
      name: "Icons",
      components: { default: Icons },
      meta: {
        groupName: "Components",
      },
    },
    {
      path: "typography",
      name: "Typography",
      components: { default: Typography },
      meta: {
        groupName: "Components",
      },
    },
  ],
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
    },
    {
      path: "/pricing",
      name: "Pricing",
      component: Pricing,
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock,
    },
  ],
};

let mapsMenu = {
  path: "/maps",
  component: DashboardLayout,
  redirect: "/maps/google",
  name: "Maps",
  children: [
    {
      path: "/maps/google",
      name: "Google",
      components: { default: GoogleMaps },
      meta: {
        groupName: "Maps",
      },
    },
    {
      path: "/maps/vector",
      name: "Vector",
      components: { default: VectorMaps },
      meta: {
        groupName: "Maps",
      },
    },
  ],
};

let formsMenu = {
  path: "/forms",
  component: DashboardLayout,
  redirect: "/forms/elements",
  name: "Forms",
  children: [
    {
      path: "elements",
      name: "Elements",
      components: { default: FormElements },
      meta: {
        groupName: "Forms",
      },
    },
    {
      path: "components",
      name: "Components",
      components: { default: FormComponents },
      meta: {
        groupName: "Forms",
      },
    },
    {
      path: "validation",
      name: "Validation",
      components: { default: FormValidation },
      meta: {
        groupName: "Forms",
      },
    },
  ],
};

let tablesMenu = {
  path: "/tables",
  component: DashboardLayout,
  redirect: "/tables/regular",
  name: "Tables menu",
  children: [
    {
      path: "regular",
      name: "Regular",
      components: { default: RegularTables },
      meta: {
        groupName: "Tables",
      },
    },
    {
      path: "sortable",
      name: "Sortable",
      components: { default: SortableTables },
      meta: {
        groupName: "Tables",
      },
    },
    {
      path: "paginated",
      name: "Paginated",
      components: { default: PaginatedTables },
      meta: {
        groupName: "Tables",
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/dashboard",
    component: DashboardLayout,
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: {
          groupName: "Dashboards",
        },
        beforeEnter: isLoggedIn
      },
      {
        path: "/settings",
        name: "Settings",
        component: Settings,
        meta: {
          groupName: "Dashboards",
        },
        beforeEnter: isLoggedIn
      },
      {
        path: "/customers",
        name: "Customers",
        component: Customers,
        meta: {
          groupName: "Dashboards",
        },
        beforeEnter: isLoggedIn
      },
      {
        path: "/alternative",
        name: "Alternative",
        component: AlternativeDashboard,
        meta: {
          groupName: "Dashboards",
        },
      },
      {
        path: "/profile",
        name: "Profile",
        components: { default: Profile },
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "/timeline",
        name: "Timeline",
        components: { default: Timeline },
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "calendar",
        name: "Calendar",
        component: Calendar,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "charts",
        name: "Charts",
        component: Charts,
        meta: {
          groupName: "Components",
        },
      },
      {
        path: "widgets",
        name: "Widgets",
        component: Widgets,
        meta: {
          groupName: "Components",
        },
      },
    ],
  },
  componentsMenu,
  authPages,
  mapsMenu,
  tablesMenu,
  formsMenu,
];

const router = new VueRouter({
  routes,
  mode: 'history'
});

export default router;
